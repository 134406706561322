"use client";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "@/features/user/userSlice";
import dashboardReducer from "../../features/dashboard/dashboardSlice";
import globalReducer from "../../features/global/globalSlice";
import interviewSlice from "@/features/interview/interviewSlice";
import modelSlice from "@/features/model/modelSlice";
import deviceSlice from "@/features/device/deviceSlice";
const userConfig = {
  key: "user",
  version: 1,
  storage,
};
const interviewConfig = {
  key: "interview",
  version: 1,
  storage,
};

//persisted Reducer
const persistedUser = persistReducer(userConfig, userReducer);
const persistedInterview = persistReducer(interviewConfig, interviewSlice);

const reducers = combineReducers({
  User: persistedUser,
  Dashboard: dashboardReducer,
  Global: globalReducer,
  Interview: persistedInterview,
  Model: modelSlice,
  Device: deviceSlice,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
