"use client";
import { Provider } from "react-redux";
import { store } from "@/app/GlobalRedux/store";
import { persistor } from "@/app/GlobalRedux/store";
import { Toaster } from "@/components/ui/toaster";
import { PersistGate } from "redux-persist/integration/react";
import { SessionProvider } from "next-auth/react";
import { usePathname } from "next/navigation";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import Footer from "@/components/Footer/Footer";
import Navbar from "@/components/Navbar/Navbar";
import NextTopLoader from "nextjs-toploader";

export function ThemeProvider({ children, ...props }) {
  return <NextThemesProvider {...props}>{children}</NextThemesProvider>;
}

export default function App({ children }) {
  const path = usePathname();
  const allowed = ["/about", "/pricing"];
  const allowedPage = allowed.some((prefix) => path.startsWith(prefix));
  return (
    <ThemeProvider
      attribute="class"
      defaultTheme="dark"
      enableSystem
      disableTransitionOnChange
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SessionProvider>
            <NextTopLoader />
            {(allowedPage || path === "/") && <Navbar />}
            {children}
            {(allowedPage || path === "/") && <Footer />}
          </SessionProvider>
          <Toaster />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}
