import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  permissionGranted: false,
  cameraFound: false,
  selectedDeviceId: {
    camera: "",
    microphone: "",
  },
  continueWithoutCamera: false,
  devices: [],
};

export const checkCamera = createAsyncThunk(
  "camera/checkCamera",
  async (_, { dispatch }) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (stream) {
        dispatch(setPermissionGranted(true));
        const devices = await navigator.mediaDevices.enumerateDevices();
        const cameras = devices.filter(
          (device) => device.kind === "videoinput"
        );
        dispatch(setDevices(cameras)); // Dispatch devices to store
        if (cameras.length > 0) {
          dispatch(setCameraFound(true));
          dispatch(
            setSelectedDeviceId({
              camera: cameras[0].deviceId,
            })
          );
        } else {
          dispatch(setCameraFound(false));
        }
      }
    } catch (error) {
      console.error("Error checking camera:", error);
      dispatch(setPermissionGranted(false));
    }
  }
);

const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    setPermissionGranted(state, action) {
      state.permissionGranted = action.payload;
    },
    setCameraFound(state, action) {
      state.cameraFound = action.payload;
    },
    setSelectedDeviceId(state, action) {
      state.selectedDeviceId = action.payload;
    },
    setContinueWithoutCamera(state, action) {
      state.continueWithoutCamera = action.payload;
    },
    setDevices(state, action) {
      state.devices = action.payload;
    },
  },
});

export const {
  setPermissionGranted,
  setCameraFound,
  setSelectedDeviceId,
  setCameraTestCompleted,
  setContinueWithoutCamera,
  setDevices,
} = deviceSlice.actions;

export default deviceSlice.reducer;
