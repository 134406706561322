import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  modelAudioFile: '',
  modelLipsyncFile: '',
  modelAudioFileLength: '',
  modelPlay: false,
};

const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    setModelPlay: (state, actions) => {
      state.modelPlay = actions.payload;
    },
    setModelAudioFile: (state, actions) => {
      state.modelAudioFile = actions.payload;
    },
    setModelLipsyncFile: (state, actions) => {
      state.modelLipsyncFile = actions.payload;
    },
    setModelAudioFileLength: (state, actions) => {
      state.modelAudioFileLength = actions.payload;
    },
  },
});

export const { setModelPlay, setModelAudioFile, setModelLipsyncFile, setModelAudioFileLength } = modelSlice.actions;

export default modelSlice.reducer;
