"use client";
import { useState } from "react";
import Link from "next/link";
import { useSession } from "next-auth/react";
import { MdOutlineMenu } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { Title } from "../Globals/Globals";
import Image from "next/image";
import { useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  const { user } = useSelector((store) => store.User);
  const { status } = useSession();
  const isAuth = status === "authenticated" ? true : false;

  const addServiceSlide = {
    initial: {
      y: 0,
    },
    enter: {
      y: "100%",
      transition: {
        duration: 0.5,
        ease: [0.65, 0, 0.35, 1],
      },
    },
    exit: {
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.65, 0, 0.35, 1],
      },
    },
  };

  return (
    <nav className="w-full max-w-[100vw] flex flex-col sticky top-0 z-10">
      <div
        className={`flex justify-between items-center w-full px-6 py-4 lg:py-5 lg:px-10  backdrop-blur-xl backdrop-saturate-150 dark:bg-black bg-muted  border-b dark:border-white/20 border-black/20`}
      >
        <div className="flex justify-around items-center gap-5 ">
          <Link href="/">
            <Title />
          </Link>
          <div className="mx-10 lg:flex justify-between items-center hidden">
            <Link
              className="grid place-items-center  p-3 w-[120px] font-medium rounded-full  bg-transparent hover:bg-blue-500/20 dark:text-white text-black text-xl"
              href="/about"
            >
              About
            </Link>
            <Link
              className="grid place-items-center  p-3 w-[120px] font-medium rounded-full  bg-transparent hover:bg-blue-500/20 dark:text-white text-black text-xl"
              href="/pricing"
            >
              Pricing
            </Link>
          </div>
        </div>

        {/* mobile navbar */}
        <button
          onClick={() => {
            setNavbar(!navbar);
          }}
          className="lg:hidden pl-10 right-5 top-[6%] dark:text-white text-black"
        >
          {isAuth ? (
            <Image
              src={user.picturePath}
              height={35}
              width={35}
              alt="userImage"
              className="rounded-full aspect-square object-cover border border-black cursor-pointer"
            />
          ) : navbar ? (
            <AiOutlineClose size={23} />
          ) : (
            <MdOutlineMenu size={23} />
          )}
        </button>

        <div className="hidden lg:flex justify-center items-center gap-8 text-xl dark:text-white text-black ">
          <Link
            href={isAuth ? "/dashboard" : "/register"}
            className=" py-3 px-5  font-bold rounded-full  bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-blue-500 hover:to-blue-600 dark:text-white text-black text-lg"
          >
            {isAuth ? "Dashboard" : "Start Preparing"}
          </Link>
          {!isAuth && (
            <Link
              href="/login"
              className="grid place-items-center  p-3 w-[120px] font-bold rounded-full  bg-blue-500/20 hover:bg-blue-600 dark:text-white text-black text-lg "
            >
              Login
            </Link>
          )}
        </div>
      </div>
      <AnimatePresence>
        {navbar && (
          <motion.div
            variants={addServiceSlide}
            animate="enter"
            initial="initial"
            exit="exit"
            className={`-z-10 lg:hidden w-full absolute bottom-[0] backdrop-blur-lg bg-blue-500/10  h-max border-b-2 dark:border-white/5 border-black/5`}
          >
            <div className="flex flex-col justify-center items-end p-4 space-y-7">
              <div className="flex flex-col justify-center gap-2 text-lg dark:text-white text-black">
                <Link
                  onClick={() => {
                    setNavbar(false);
                  }}
                  href="/about"
                >
                  About
                </Link>
                <Link
                  onClick={() => {
                    setNavbar(false);
                  }}
                  href="/pricing"
                >
                  Pricing
                </Link>
                {isAuth ? (
                  <Link className=" " href="/dashboard">
                    Dashboard
                  </Link>
                ) : (
                  <>
                    <Link href="/register">Signup</Link>
                    <Link href="/login">Login</Link>
                  </>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;
